import type {
  Breakpoints,
  Colors,
  Fonts,
  Spacings,
  ElementsHeight,
} from 'types/theme'

export const breakpoints: Breakpoints = {
  base: 0,
  xs: 440,
  sm: 576,
  md: 768,
  lg: 992,
  xl: 1200,
  xxl: 1440,
  '3xl': 1600,
  '4xl': 1920,
  '5xl': 2400,
}

export const colors: Colors = {
  primary100: '#3C8A26',
  primary50: '#509E2F',
  primary20: '#65B239',
  primary10: '#9ED47B',
  primary05: '#D6EDC6',
  primary0: '#EEF8E8',
  secondary100: '#A82934',
  secondary50: '#B8313F',
  secondary20: '#E74750',
  secondary10: '#E49BA3',
  secondary05: '#F7CED7',
  secondary0: '#FBEBF0',
  text100: '#15310D',
  gray100: '#353535',
  gray80: '#535353',
  gray60: '#8E8E8E',
  gray40: '#BEBEC5',
  gray10: '#D3D3D3',
  gray05: '#EFEFEF',
  gray0: '#F8F8F8',
  white: '#FFFFFF',
  white72: '#FFFFFFB8',
  white40: '#FFFFFF66',
  white12: '#FFFFFF1E',
  black: '#000000',
  success100: '#116942',
  success50: '#1BA769',
  success05: '#DEF2E9',
  warning100: '#A64F21',
  warning50: '#E86825',
  warning05: '#FFE1BE',
  alert100: '#9F1B1F',
  alert50: '#DE1C22',
  alert05: '#FFE0E1',
}

export const fonts: Fonts = {
  size: '16px',
}

export const spacings: Spacings = {
  mobile: {
    SM: '24px',
    MD: '32px',
    LG: '48px',
  },
  desktop: {
    SM: '48px',
    MD: '80px',
    LG: '120px',
  },
}

export const elementsHeight: ElementsHeight = {
  navbar: {
    mobile: '72px',
    desktop: '112px',
  },
  searchbar: '112px',
}

const theme = {
  breakpoints,
  colors,
  fonts,
  spacings,
  elementsHeight,
}

export default theme
