import React, { createContext, useState, useEffect, useMemo } from 'react'
import useLocation from 'hooks/useLocation'

type NavigationProviderProps = {
  children: React.ReactNode
}

type NavigationContextType = {
  isDesktopNavHidden: boolean
  isSidenavVisible: boolean
  isSearchbarVisible: boolean
  isSidenavDesktopVisible: boolean
  hideNav: () => void
  openSidenav: () => void
  openSearchBar: () => void
  openSidenavDesktop: () => void
  closeSidenav: () => void
  closeSearchBar: () => void
  closeSidenavDesktop: () => void
}

const defaultContextValues = {
  isDesktopNavHidden: false,
  isSidenavVisible: false,
  isSearchbarVisible: false,
  isSidenavDesktopVisible: false,
  hideNav: () => null,
  openSidenav: () => null,
  openSearchBar: () => null,
  openSidenavDesktop: () => null,
  closeSidenav: () => null,
  closeSearchBar: () => null,
  closeSidenavDesktop: () => null,
}
const NavigationContext =
  createContext<NavigationContextType>(defaultContextValues)

export const NavigationProvider: React.FC<NavigationProviderProps> = ({
  children,
}) => {
  const [isDesktopNavHidden, setIsDesktopNavHidden] = useState(false)
  const [isSidenavVisible, setIsSidenavVisible] = useState(false)
  const [isSidenavDesktopVisible, setIsSidenavDesktopVisible] = useState(false)
  const [isSearchbarVisible, setIsSearchbarVisible] = useState(false)

  let prevScroll = 0

  const handleScroll = (e: Event) => {
    const window = e.currentTarget as Window

    if (prevScroll > window.scrollY) {
      setIsDesktopNavHidden(false)
    } else if (window.scrollY >= 100) {
      setIsDesktopNavHidden(true)
    }
    prevScroll = window.scrollY
  }

  const hideNav = () => {
    if (window.scrollY >= 100) setIsDesktopNavHidden(true)
  }

  const closeSidenav = () => {
    if (isSidenavVisible) {
      setIsSidenavVisible(false)
      setTimeout(hideNav, 800)
      document.body.style.overflow = 'auto'
    }
  }

  const openSidenav = () => {
    if (!isSidenavVisible) {
      setIsSidenavVisible(true)
      document.body.style.overflow = 'hidden'
    }
  }

  const closeSearchBar = () => {
    if (isSearchbarVisible) {
      setIsSearchbarVisible(false)
      document.body.style.overflow = 'auto'
    }
  }

  const openSearchBar = () => {
    if (!isSearchbarVisible) {
      setIsSearchbarVisible(true)
      document.body.style.overflow = 'hidden'
    }
  }

  const openSidenavDesktop = () => {
    if (!isSidenavDesktopVisible) {
      setIsSidenavDesktopVisible(true)
    }
  }

  const closeSidenavDesktop = () => {
    if (isSidenavDesktopVisible) {
      setIsSidenavDesktopVisible(false)
    }
  }

  useEffect(() => {
    window.addEventListener('scroll', (e) => handleScroll(e))
    return () => {
      window.removeEventListener('scroll', (e) => handleScroll(e))
    }
  }, [])

  const value = useMemo(
    () => ({
      isDesktopNavHidden,
      isSidenavVisible,
      isSearchbarVisible,
      isSidenavDesktopVisible,
      hideNav,
      openSidenav,
      openSidenavDesktop,
      openSearchBar,
      closeSidenav,
      closeSidenavDesktop,
      closeSearchBar,
    }),
    [
      isDesktopNavHidden,
      isSidenavVisible,
      isSearchbarVisible,
      isSidenavDesktopVisible,
      hideNav,
      openSidenav,
      openSearchBar,
      openSidenavDesktop,
      closeSidenav,
      closeSidenavDesktop,
      closeSearchBar,
    ]
  )

  return (
    <NavigationContext.Provider value={value}>
      {children}
    </NavigationContext.Provider>
  )
}

export default NavigationContext
